:root {
  --main-color: #75F3FB;
  --main-lignt-color: #BFFBFF;
}

* {
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  color: #444;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

button {
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
  padding: 10px;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  width: 100%;
  transition: background-color 0.2s ease-in-out 0s;
  white-space: nowrap;
}

button:disabled {
  opacity: 0.7;
  cursor: default;
}

button:hover {
  background-color: var(--main-lignt-color);
  border-color: var(--main-lignt-color);
  color: #fff;
}

button:active {
  box-shadow: 2px 1px 3px rgba(0,0,0, 0.1) inset;
}

input {
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 13px;
  font-weight: 400;
  width: 100%;
  transition: all 0.2s ease-in-out 0s;
  margin-bottom: 10px;
  outline: none;
}

input:hover {
  border-color: var(--main-lignt-color);
}

.Main_Title {
  text-align: center;
}

.Button_Container {
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  position: relative;
}

.Small_Container {
  width: 320px;
  background-color: #fafafa;
  padding: 20px;
  box-shadow: 0 0 3px rgba(0,0,0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}


.Error_Message {
  position: absolute;
  top: 10px;
  text-align: center;
  color: #b65353;
  font-size: 11px;
}

.Success_Message {
  position: absolute;
  top: 10px;
  text-align: center;
  color: #56b653;
  font-size: 11px;
}

